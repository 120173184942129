import moment from "moment";
import React from "react";

export default function GalleryItem({ eventImage, eventDate, eventName }) {
  const date = new Date(eventDate);
  const options = { month: "long", weekday: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [weekday, month, numericDay] = formattedDate.split(" ");

  return (
    <div className="relative w-[343px] h-[313px] m-3.5">
      {/*  sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-72 lg:h-72 ^^^ */}
      <img
        src={eventImage}
        alt="Event"
        className="w-full h-full object-cover"
      />
      <div 
        style={{ 
          background: 'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1))'
        }} 
        className="absolute inset-0"
      ></div>
      <div className="absolute inset-0 text-white bg-black bg-opacity-50 flex flex-col items-center justify-end text-center pb-6 px-2">
        <span className="text-[3.5vw] md:text-[1vw] leading-none">
        {moment(eventDate).format('MMM D YYYY')}
        </span>
        
        <span className="text-[6.2vw] md:text-[2vw] font-semibold leading-tight mt-2 text-center">
          {eventName}
        </span>
      </div>
    </div>
  );
}
