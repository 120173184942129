import moment from "moment";
import { React } from "react";

export default function EventCard({event}) {

  return (
    <div className="h-[356px] w-[343px] rounded-[5px] m-[14px] cursor-pointer event-card hover:drop-shadow-[0_0_10px_rgba(255,255,255,0.25)] transition-all duration-300">
      <img
        src={event?.promo?.banner || event?.promo?.story}
        alt="Event"
        className="w-[343px] h-[194px] object-cover object-center"
      />
      {/* Bottom Section */}
      <div className="w-[343px] h-[146px] flex pb-[24px] pt-[24px] hover:drop-shadow-[0_0_10px_rgba(255,255,255,0.25)] transition-all duration-300">
        {/* Date Section */}
        <div className="flex flex-col items-center justify-center mx-[11px] ">
          <span className="text-white text-[10px] font-normal">{moment(event?.start_date).format('MMM')}</span>
          <span className="text-white text-3xl font-bold">{moment(event?.start_date).format('DD')}</span>
          <span className="text-white text-[10px] font-light">{moment(event?.start_date).format('ddd')}</span>
        </div>
        {/* Event Info Section */}
        <div className="flex flex-col items-left justify-center pl-[15px] pr-[34px] border-l-white border-l-[2px]">
          <span className="text-white text-[20px] font-bold mb-[0px]">{event?.name}</span>
          <span className="text-white text-[14px] font-medium">{event?.organization?.name} | {event?.organization?.age} | {moment(event?.start_date).format('MMM D, YYYY')} | {event?.organization?.city} {event?.organization?.state}</span>
        </div>
      </div>
    </div>
  );
}