import React, { useContext, useState } from "react";
import SocialMediaWrapper from "../rj-components/SocialMediaWrapper";
import SiteButton from "../rj-components/SiteButton";
import moment from "moment";
import { Link } from "react-router-dom";
import CompanyLogo from "../../layouts/CompanyLogo";
import GlobalContext from "../../contexts/store";
import { toast } from "react-toastify";
import { Post } from "../../api/server";
import formatPhoneNumber from "../../helpers/phoneNumberFormatter";

function RJFooter() {
  const { company } = useContext(GlobalContext);
  const [focused, setFocused] = useState(false);

  const [values, setValues] = useState({
    email: "",
  });

  const resetForm = () => {
    setValues({
      email: "",
    });
  };

  const handleFocused = (e) => {
    setFocused(true);
  };

  const notify = (data, type) => {
    toast(data, {
      type: type || "default",
      theme: "dark",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (values.email === '') {
      notify('Please fill out all fields', 'error');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(values.email)) {
      notify('Please enter a valid email address', 'error');
      return;
    }
    Post("/users", {
      email: values.email,
      company: company._id,
    })
      .then(() => {
        resetForm();
        notify("Thank you for subscribing!", "success");
      })
      .fail((err) => {
        resetForm();
        notify(err.responseJSON, "error");
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <footer className="flex justify-center items-center pb-16 bg-black">
      <div className="flex flex-col w-full mt-4 px-[5%]">
        <div className="items-center flex flex-col md:flex-row justify-around w-full pb-[0px] space-y-8 md:space-y-0 md:space-x-8">
          <div className="flex flex-col items-center ml-0 md:ml-6">
            <CompanyLogo isDark={false} />
          </div>

          <div className="flex flex-col items-center md:items-start">
            <h5 className="font-bold text-white">TABLES</h5>
            <a
              href={`mailto:${company?.email}`}
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              {company?.email}
            </a>
            <br />
            <Link className="no-underline" to={"/terms"}>
              <p className="opacity-60 text-white">Privacy Policy</p>
            </Link>
          </div>

          <div className="flex flex-col items-center md:items-start">
            <h5 className="font-bold text-white">CONTACT</h5>
            <a
              href={`mailto:${company?.email}`}
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              {company?.email}
            </a>
            <a
              href={`tel:${company?.phone}`}
              className="opacity-60 text-white mb-[-3px] no-underline"
            >
              {formatPhoneNumber(company?.phone)}
            </a>
            <br />
            <p className="opacity-60 text-white mb-[-3px]">
              {company?.address}
            </p>
            <p className="opacity-60 text-white mb-[-3px]">
              {company?.city}, {company?.state} {company?.zip}
            </p>
          </div>

          <div className="flex flex-col items-center md:items-start flex-grow max-w-full md:max-w-[20%]">
            <h5 className="font-bold text-white">SUBSCRIBE</h5>
            <p className="opacity-60 text-white">Email Address</p>
            <input
              className="bg-black border-b border-gray-400 outline-none text-white mb-2 py-2 w-full placeholder:text-gray-500"
              value={values.email}
              name="email"
              placeholder="Enter your email"
              onChange={onChange}
              onBlur={handleFocused}
            />
            <SiteButton buttonText={"Subscribe"} onClick={handleSubmit} />
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full border-b pb-[60px] border-gray-400 space-y-8 md:space-y-0 md:space-x-8 mt-8 md:mt-0 gap-5">
          <div className="flex flex-col items-center md:items-center">
            <img src={'https://sfclubs.s3-us-west-1.amazonaws.com/logos/1681716523655_clubxLogo.png'} alt="logo" className="w-[65px] h-[65px] object-cover rounded-full" />
            {/* <SocialMediaWrapper /> */}
          </div>
          <div className="flex flex-col items-center md:items-center">
            <img src={'https://sfclubs.s3-us-west-1.amazonaws.com/logos/1681717536948_ritmoLogo.png'} alt="logo" className="w-[65px] h-[65px] object-cover rounded-full" />
            {/* <SocialMediaWrapper /> */}
          </div>
          <div className="flex flex-col items-center md:items-center">
            <img src={'https://sfclubs.s3-us-west-1.amazonaws.com/logos/1710893128289_Untitled_(625_x_500_px)_(4).png'} alt="logo" className="w-[65px] h-[65px] object-cover rounded-full" />
            {/* <SocialMediaWrapper /> */}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <span className="text-white mt-4">
            © {moment().year()} {company?.name}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default RJFooter;
