import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function ContactMap({ zoom, height, width, popupText, icon, link }) {
  const getNativeMapsLink = (googleMapsLink) => {
    // Extract coordinates from Google Maps URL
    const match = googleMapsLink.match(/query=([^&]+)/);
    if (!match) return googleMapsLink;
    
    const address = match[1];
    
    // Detect if user is on iOS or Android
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/i.test(navigator.userAgent);
    
    if (isIOS) {
      return `maps:0,0?q=${address}`;
    } else if (isAndroid) {
      return `geo:0,0?q=${address}`;
    }
    
    return googleMapsLink; // Fallback to web version
  };

  const nativeLink = link ? getNativeMapsLink(link) : null;

  const iconPerson = new L.Icon({
    iconUrl: icon || require('../assets/markers/3.png'),
    iconSize: [85, 85], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [48.5, 70], // point of the icon which will correspond to marker's location (center bottom)
    popupAnchor: [0, -97] // point from which the popup should open relative to the iconAnchor
  });

  return (
    <div className='map' style={{ position: 'relative', zIndex: 0 }}>
      <style>
        {`
          .leaflet-map-pane,
          .leaflet-tile,
          .leaflet-marker-icon,
          .leaflet-marker-shadow,
          .leaflet-tile-pane,
          .leaflet-overlay-pane,
          .leaflet-shadow-pane,
          .leaflet-marker-pane,
          .leaflet-popup-pane,
          .leaflet-control {
            z-index: 1 !important;
          }
          .leaflet-control-container {
            z-index: 2 !important;
          }
        `}
      </style>
      <MapContainer style={{ height: height || 500, width: width || '100%' }} id='mapid' center={[37.782147, -122.398917]} zoom={zoom || 17} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://Liveonbeat.com">Live On Beat</a>'
          url="https://api.mapbox.com/styles/v1/djstewie/cm57s12mx00oc01svcop25fjn/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGpzdGV3aWUiLCJhIjoiY2trcGQ2MW84MDZhNjJ5bzVrbm8xemhycSJ9.y592_-MfDaduUdSMbT66iQ"
        />
        <Marker position={[37.782047, -122.397600]} icon={iconPerson}>
          {popupText && (
            <Popup>
              {popupText}
              {nativeLink && (
                <div style={{ marginTop: '8px' }}>
                  <a href={nativeLink} target="_blank" rel="noopener noreferrer">Get Directions</a>
                </div>
              )}
            </Popup>
          )}
        </Marker>
      </MapContainer>
    </div>
  )
}
